import { mapActions } from 'vuex'
import  { myMixin } from '../../../mixins';

export default{
  mixins: [myMixin],
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    showImage: false,
    onlyActive: true,
    selectedAmbience: null,
    model: {},
    show: false,
    showForm: false,
    text: 'Carregando dados...',
    loadingProgress: false,
    textDelete: '',
    payloadFilter: null,
    dialogFilter: false,
    deleting: false,
    
      headers: [
        { text: 'Código', value: 'code'},
        { text: 'Código Sala', value: 'code_control'},
        { text: 'Campus', value: 'campus'},
        { text: 'Prédio', value: 'place.name'},
        { text: 'Bloco', value: 'block.name'},
        { text: 'Andar', value: 'floor.name'},
        { text: 'Número/Nome', value: 'name'},
        { text: 'Tipo', value: 'type'},
        { text: 'Capacidade', value: 'capacity',  align: 'center'},
        // { text: 'Indisponível', value: 'not_available', type: 'boolean', align: 'center'},
        // { text: 'Validado', value: 'validated', type: 'boolean', align: 'center'},
        { text: 'Status', value: 'active', type: 'boolean'},
        { text: 'Ações', value: 'actions', sortable: false}
    ]
    }),
    
    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },

        onlyActive(val) {
        
            this.getData();

        },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir " + item_new.name + "?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
    },

    methods: {
      ...mapActions('ambience', ['ActionFindAmbiences', 'ActionDeleteAmbience']),
        
      getData(){
      
        this.error = false;
        this.message = '';
        let params = this.params;
        this.loadingProgress = true;
        this.showForm = false;

        delete params.filter_relations;
        delete params.find_columns;

        Object.assign(params, { with: 'type,place,campus,floor,block,resources,images,availabilities,allocationDays' });

        if(this.onlyActive){
            params.find_columns = {
                active: 1
            }
        }else{
            delete  params.find_columns;
        }

        if(this.payloadFilter){
            
            if(this.payloadFilter.filter_relations){
                params.filter_relations = this.payloadFilter.filter_relations;
            }

            if(!params.find_columns){
                params.find_columns = {};
            }
            
            params.find_columns.campus_id = this.payloadFilter.find_columns.campus_id;
            
        }
        
        this.ActionFindAmbiences(params)
            .then((res) => {
                this.collections = res.data
                this.meta = res.meta
            })
            .finally(() => {
                this.loading = false;
                this.loadingProgress = false;
            })
    },

    filter(payload){
        this.payloadFilter = payload;
        this.dialogFilter = false;
        this.getData();
    },

    deleteItem()
        {
            this.deleting = true;
            this.message = '';

            this.ActionDeleteAmbience({id: this.itemDelete.id})
                .then((res) => {
                    this.message = res.message;
                    this.show = false;
                    this.getData();
                })
                .catch((error) =>{
                  this.message = error.message;
                  this.error = true;
                })
                .finally(() => {
                    this.deleting = false;
                });
        },

    showAvatar(item){
        
        if(item && item.url_avatar){
            this.selectedAmbience = item;
            this.showImage = true;
        }
    }

  },

}
